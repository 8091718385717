import type { SitemapData } from "~/types/drupal_jsonapi";
import CommonFirstScreenImage from "~/components/shared/CommonFirstScreenImage";
import { For } from "solid-js";
import { urlRs } from "~/utils/url";

import "~/components/shared/CommonPageStyle.css";
import "./SiteMapPage.css";

export default function SiteMapPage(props: { data: SitemapData }) {
  return (
    <>
      <article class="common-page-style seo-sitemap" data-ga-zone="in-page">
        <CommonFirstScreenImage image="/images/seo-sitemap-bg.jpg" alt="" />

        <div class="outer-content-area">
          <section class="content-area">
            <div class="inner">
              <div data-test="introduction">
                <h1>Immobilier neuf en France avec Cogedim</h1>
                <h2 class="page-catchline" data-test="catchline">
                  Localisez votre logement
                </h2>
              </div>

              <div class="geographies-list" data-test="geographies">
                <For each={props.data}>
                  {(region) => (
                    <details data-test={region.level}>
                      <summary>
                        <a href={urlRs("geographies", region.url)}>
                          {region.name}
                        </a>
                      </summary>
                      <For each={region.children}>
                        {(department) => (
                          <details data-test={department.level}>
                            <summary>
                              <a href={urlRs("geographies", department.url)}>
                                {department.name}
                              </a>
                            </summary>
                            <For each={department.children}>
                              {(city) => (
                                <a
                                  href={urlRs("geographies", city.url)}
                                  data-test={city.level}
                                >
                                  {city.name}
                                </a>
                              )}
                            </For>
                          </details>
                        )}
                      </For>
                    </details>
                  )}
                </For>
              </div>
            </div>
          </section>
        </div>
      </article>
    </>
  );
}
